var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center node",class:[
      `pl-${3 * _vm.level}`,
      { 'light-blue lighten-4': _vm.selectedCopy.key === _vm.nodeCopy.key },
    ]},[_c('div',{staticClass:"d-flex align-center ml-2 w-100"},[_c('v-btn',{attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){{
            _vm.nodeCopy.isExpanded = _vm.nodeCopy.children?.length
              ? !_vm.nodeCopy.isExpanded
              : _vm.nodeCopy.isExpanded;
          }}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.nodeCopy.children?.length ? _vm.nodeCopy.isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" : "mdi-vector-point"))])],1),_c('div',{staticClass:"d-flex w-100",on:{"click":_vm.clickHandler}},[_c('v-icon',{attrs:{"color":_vm.nodeCopy.iconColor,"small":""}},[_vm._v(_vm._s(_vm.nodeCopy.contractedIcon))]),_c('div',{staticClass:"ml-2"},[(!_vm.nodeCopy.isEditable)?_c('p',{staticClass:"ma-0 text-body-2 py-2"},[_vm._v(" "+_vm._s(_vm.nodeCopy.label)+" ")]):_c('v-text-field',{staticClass:"py-1",attrs:{"dense":"","hide-details":"","color":"blue darken-2","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){{
                    _vm.nodeCopy.isEditable = false;
                  }}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")])],1)]},proxy:true}]),model:{value:(_vm.nodeCopy.label),callback:function ($$v) {_vm.$set(_vm.nodeCopy, "label", $$v)},expression:"nodeCopy.label"}})],1)],1)],1),_c('div',{staticClass:"d-flex"},_vm._l((_vm.nodeCopy.actions),function(action,index){return _c('v-btn',{key:`${_vm.nodeCopy.key}-${index}`,attrs:{"icon":"","small":""},on:{"click":function($event){return action.action(_vm.nodeCopy)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(action.icon))])],1)}),1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nodeCopy.isExpanded),expression:"nodeCopy.isExpanded"}]},_vm._l((_vm.nodeCopy.children),function(nod,ind){return _c('tree-node',{key:`${_vm.nodeCopy.key}-${ind}`,attrs:{"selected":_vm.selectedCopy,"level":_vm.level + 1},on:{"update:selected":function($event){_vm.selectedCopy=$event}},model:{value:(_vm.nodeCopy.children[ind]),callback:function ($$v) {_vm.$set(_vm.nodeCopy.children, ind, $$v)},expression:"nodeCopy.children[ind]"}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }