const colors = [
  [
    "#f44336",
    "#b71c1c",
    "#c62828",
    "#d32f2f",
    "#e53935",
    "#ef5350",
    "#e57373",
    "#ef9a9a",
    "#ffcdd2",
    "#ffebee",
  ],
  [
    "#e91e63",
    "#880e4f",
    "#ad1457",
    "#c2185b",
    "#d81b60",
    "#ec407a",
    "#f06292",
    "#f48fb1",
    "#f8bbd0",
    "#fce4ec",
  ],
  [
    "#9c27b0",
    "#4a148c",
    "#6a1b9a",
    "#7b1fa2",
    "#8e24aa",
    "#ab47bc",
    "#ba68c8",
    "#ce93d8",
    "#e1bee7",
    "#f3e5f5",
  ],
  [
    "#673ab7",
    "#311b92",
    "#4527a0",
    "#512da8",
    "#5e35b1",
    "#7e57c2",
    "#9575cd",
    "#b39ddb",
    "#d1c4e9",
    "#ede7f6",
  ],
  [
    "#3f51b5",
    "#1a237e",
    "#283593",
    "#303f9f",
    "#3949ab",
    "#5c6bc0",
    "#7986cb",
    "#9fa8da",
    "#c5cae9",
    "#e8eaf6",
  ],
  [
    "#2196f3",
    "#0d47a1",
    "#1565c0",
    "#1976d2",
    "#1e88e5",
    "#42a5f5",
    "#64b5f6",
    "#90caf9",
    "#bbdefb",
    "#e3f2fd",
  ],
  [
    "#03a9f4",
    "#01579b",
    "#0277bd",
    "#0288d1",
    "#039be5",
    "#29b6f6",
    "#4fc3f7",
    "#81d4fa",
    "#b3e5fc",
    "#e1f5fe",
  ],
  [
    "#00bcd4",
    "#006064",
    "#00838f",
    "#0097a7",
    "#00acc1",
    "#26c6da",
    "#4dd0e1",
    "#80deea",
    "#b2ebf2",
    "#e0f7fa",
  ],
  [
    "#009688",
    "#004d40",
    "#00695c",
    "#00796b",
    "#00897b",
    "#26a69a",
    "#4db6ac",
    "#80cbc4",
    "#b2dfdb",
    "#e0f2f1",
  ],
  [
    "#4caf50",
    "#1b5e20",
    "#2e7d32",
    "#388e3c",
    "#43a047",
    "#66bb6a",
    "#81c784",
    "#a5d6a7",
    "#c8e6c9",
    "#e8f5e9",
  ],
  [
    "#8bc34a",
    "#33691e",
    "#558b2f",
    "#689f38",
    "#7cb342",
    "#9ccc65",
    "#aed581",
    "#c5e1a5",
    "#dcedc8",
    "#f1f8e9",
  ],
  [
    "#cddc39",
    "#827717",
    "#9e9d24",
    "#afb42b",
    "#c0ca33",
    "#d4e157",
    "#dce775",
    "#e6ee9c",
    "#f0f4c3",
    "#f9fbe7",
  ],
  [
    "#ffeb3b",
    "#f57f17",
    "#f9a825",
    "#fbc02d",
    "#fdd835",
    "#ffee58",
    "#fff176",
    "#fff59d",
    "#fff9c4",
    "#fffde7",
  ],
  [
    "#ffc107",
    "#ff6f00",
    "#ff8f00",
    "#ffa000",
    "#ffb300",
    "#ffca28",
    "#ffd54f",
    "#ffe082",
    "#ffecb3",
    "#fff8e1",
  ],
  [
    "#ff9800",
    "#e65100",
    "#ef6c00",
    "#f57c00",
    "#fb8c00",
    "#ffa726",
    "#ffb74d",
    "#ffcc80",
    "#ffe0b2",
    "#fff3e0",
  ],
  [
    "#ff5722",
    "#bf360c",
    "#d84315",
    "#e64a19",
    "#f4511e",
    "#ff7043",
    "#ff8a65",
    "#ffab91",
    "#ffccbc",
    "#fbe9e7",
  ],
  [
    "#795548",
    "#3e2723",
    "#4e342e",
    "#5d4037",
    "#6d4c41",
    "#8d6e63",
    "#a1887f",
    "#bcaaa4",
    "#d7ccc8",
    "#efebe9",
  ],
  [
    "#607d8b",
    "#263238",
    "#37474f",
    "#455a64",
    "#546e7a",
    "#78909c",
    "#90a4ae",
    "#b0bec5",
    "#cfd8dc",
    "#eceff1",
  ],
  [
    "#9e9e9e",
    "#212121",
    "#424242",
    "#616161",
    "#757575",
    "#bdbdbd",
    "#e0e0e0",
    "#eeeeee",
    "#f5f5f5",
    "#fafafa",
  ],
  ["#000000", "#ffffff", "transparent"],
].flat();

export default colors;
