<template>
  <div>
    <div
      class="d-flex justify-space-between align-center node"
      :class="[
        `pl-${3 * level}`,
        { 'light-blue lighten-4': selectedCopy.key === nodeCopy.key },
      ]"
    >
      <div class="d-flex align-center ml-2 w-100">
        <v-btn
          icon
          small
          color="info"
          @click="
            {
              nodeCopy.isExpanded = nodeCopy.children?.length
                ? !nodeCopy.isExpanded
                : nodeCopy.isExpanded;
            }
          "
        >
          <v-icon small>{{
            nodeCopy.children?.length
              ? nodeCopy.isExpanded
                ? "mdi-chevron-up"
                : "mdi-chevron-down"
              : "mdi-vector-point"
          }}</v-icon>
        </v-btn>
        <div class="d-flex w-100" @click="clickHandler">
          <v-icon :color="nodeCopy.iconColor" small>{{
            nodeCopy.contractedIcon
          }}</v-icon>
          <div class="ml-2">
            <p v-if="!nodeCopy.isEditable" class="ma-0 text-body-2 py-2">
              {{ nodeCopy.label }}
            </p>
            <v-text-field
              v-else
              v-model="nodeCopy.label"
              dense
              hide-details
              class="py-1"
              color="blue darken-2"
              solo
            >
              <template #append>
                <v-btn
                  icon
                  small
                  @click="
                    {
                      nodeCopy.isEditable = false;
                    }
                  "
                >
                  <v-icon small>mdi-content-save</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          v-for="(action, index) in nodeCopy.actions"
          :key="`${nodeCopy.key}-${index}`"
          icon
          small
          @click="action.action(nodeCopy)"
        >
          <v-icon small>{{ action.icon }}</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-show="nodeCopy.isExpanded">
      <tree-node
        v-for="(nod, ind) in nodeCopy.children"
        :key="`${nodeCopy.key}-${ind}`"
        v-model="nodeCopy.children[ind]"
        :selected.sync="selectedCopy"
        :level="level + 1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeNode",
  props: {
    level: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      nodeCopy: null,
      selectedCopy: {},
    };
  },
  watch: {
    selectedCopy: {
      deep: true,
      immediate: true,
      handler(current) {
        if (JSON.stringify(current) !== JSON.stringify(this.selected)) {
          this.$emit("update:selected", { ...current });
        }
      },
    },
    selected: {
      deep: true,
      immediate: true,
      handler(current) {
        if (JSON.stringify(current) !== JSON.stringify(this.selectedCopy)) {
          this.selectedCopy = { ...current };
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(current) {
        if (JSON.stringify(current) !== JSON.stringify(this.nodeCopy)) {
          this.nodeCopy = { ...current };
        }
      },
    },
    nodeCopy: {
      deep: true,
      immediate: true,
      handler(current) {
        if (JSON.stringify(current) !== JSON.stringify(this.value)) {
          this.$emit("input", current);
        }
      },
    },
  },
  methods: {
    clickHandler() {
      const isFile = this.nodeCopy.type === "file";
      this.nodeCopy.isSelected = isFile;
      if (isFile) {
        this.selectedCopy = JSON.parse(JSON.stringify(this.nodeCopy));
      }
    },
  },
};
</script>
