<template>
  <div class="h-100">
    <v-app-bar color="teal darken-3" dark dense :elevation="0" :height="35">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      class="elevation-1"
      :width="400"
      absolute
      temporary
    >
      <tree v-model="root" :selected.sync="nodeSelected" />
    </v-navigation-drawer>
    <div class="teal lighten-5 draw-section-height">
      <div class="d-flex white align-center px-3">
        <div style="width: 85px" class="mr-1">
          <v-select
            v-model="canvasOption.color"
            :items="colors"
            hide-details
            solo
          >
            <template #item="{ item }">
              <div class="px-4 py-2" :style="{ background: item }" />
            </template>
            <template #selection="{ item }">
              <div class="px-4 py-2" :style="{ background: item }" />
            </template>
          </v-select>
        </div>
        <div style="width: 120px" class="mr-1">
          <v-select
            v-model="canvasOption.size"
            :items="sizes"
            hide-details
            solo
          >
            <template #item="{ item }">
              <div class="w-100 black" :style="{ height: `${item}px` }" />
            </template>
            <template #selection="{ item }">
              <div class="w-100 black" :style="{ height: `${item}px` }" />
            </template>
          </v-select>
        </div>
        <div>
          <v-btn
            :disabled="!pathsry.length"
            icon
            color="info"
            tile
            height="48"
            :elevation="1"
            @click="undo"
            ><v-icon small>mdi-undo</v-icon></v-btn
          >
        </div>
      </div>

      <div id="zone" class="pa-5 draw-section-height">
        <canvas class="elevation-1 white" id="drawZone" />
      </div>
    </div>
  </div>
</template>

<script>
import Tree from "../components/Tree.vue";
import COLORS from "../utils/colors.js";
import axios from "axios";
const EMPTY_OBJECT = {
  actions: [],
  canvas: null,
  children: [],
  contractedIcon: "",
  expandedIcon: "",
  iconColor: "",
  isEditable: true,
  isExpanded: false,
  isSelected: false,
  key: 0,
  label: "Unnamed",
  type: "",
};
export default {
  name: "HomePage",
  components: { Tree },
  data() {
    return {
      canvas: null,
      colors: COLORS,
      sizes: [2, 4, 6, 8, 11],
      context: null,
      isDrawing: false,
      ongoingTouches: [],
      x: 0,
      y: 0,
      offsetX: 0,
      offsetY: 0,
      canvasWidth: 0,
      canvasHeight: 0,
      drawer: true,
      isDrawZoneEnabled: false,
      root: {
        actions: [
          {
            icon: "mdi-pencil",
            label: "Editar nombre",
            action: (node) => {
              node.isEditable = true;
            },
          },
          {
            icon: "mdi-folder-plus",
            label: "Crear carpeta",
            action: (node) => {
              this.createNew(node, "folder");
            },
          },
          {
            icon: "mdi-file-plus",
            label: "Crear archivo",
            action: (node) => {
              this.createNew(node, "file");
            },
          },
          {
            icon: "mdi-download",
            label: "Crear zip",
            action: (node) => {
              this.generate(node);
            },
          },
        ],
        children: [],
        contractedIcon: "mdi-folder",
        expandedIcon: "mdi-folder-open",
        iconColor: "amber",
        isEditable: true,
        isExpanded: false,
        isSelected: false,
        key: Math.random() * 1000,
        label: "Unnamed",
        type: "folder",
      },
      nodeSelected: {},
      canvasOption: {
        color: "#000000",
        size: 2,
      },
      pathsry: [],
      points: [],

      mouse: { x: 0, y: 0 },
      previous: { x: 0, y: 0 },
    };
  },
  watch: {
    nodeSelected: {
      deep: true,
      handler() {
        const element = document.getElementById("zone");
        this.canvas.width = element.clientWidth - 40;
        this.canvas.height = element.clientHeight - 60;
      },
    },
  },
  methods: {
    saveCanvas(elem) {
      elem.canvas = this.canvas.toDataURL("image/png");
    },
    async generate() {
      const { data: response } = await axios.post(
        `${process.env.VUE_APP_API}/zip`,
        this.root
      );
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = `data:application/zip;base64,${response.data}`;
      a.download = `${this.root.label}.zip`;
      a.click();
      a.remove();
    },
    drawPaths() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.pathsry.forEach((path) => {
        if (path[0]?.x && path[0]?.y) {
          this.context.beginPath();
          this.context.moveTo(path[0].x, path[0].y);
          for (let i = 1; i < path.length; i++) {
            this.context.lineTo(path[i].x, path[i].y);
          }
          this.context.stroke();
        }
      });
    },

    undo() {
      if (this.pathsry.length) {
        this.pathsry.splice(-1);
        this.drawPaths();
      }
    },
    oMousePos(canvas, evt) {
      const ClientRect = canvas.getBoundingClientRect();
      return {
        x: Math.round(evt.clientX - ClientRect.left),
        y: Math.round(evt.clientY - ClientRect.top),
      };
    },
    startup() {
      this.canvas.addEventListener("touchstart", (e) =>
        this.handleStart(e.changedTouches[0])
      );
      this.canvas.addEventListener("touchend", (e) =>
        this.handleEnd(e.changedTouches[0])
      );
      this.canvas.addEventListener("touchmove", (e) =>
        this.handleMove(e.changedTouches[0])
      );
      this.canvas.addEventListener("mousedown", (e) => this.handleStart(e));

      this.canvas.addEventListener("mousemove", (e) => this.handleMove(e));

      this.canvas.addEventListener("mouseup", () => this.handleEnd());
    },
    handleStart(e) {
      this.previous = { x: this.mouse.x, y: this.mouse.y };
      this.mouse = this.oMousePos(this.canvas, e);
      this.points = [];
      this.points.push({ x: this.mouse.x, y: this.mouse.y });
      this.isDrawing = true;
    },

    handleMove(e) {
      if (this.isDrawing) {
        this.previous = { x: this.mouse.x, y: this.mouse.y };
        this.mouse = this.oMousePos(this.canvas, e);
        // saving the points in the points array
        this.points.push({ x: this.mouse.x, y: this.mouse.y });
        // drawing a line from the this.previous point to the current point
        this.context.beginPath();
        this.context.lineWidth = this.canvasOption.size;
        this.context.strokeStyle = this.canvasOption.color;
        this.context.lineJoin = "round";
        this.context.moveTo(this.previous.x, this.previous.y);
        this.context.lineTo(this.mouse.x, this.mouse.y);
        this.context.stroke();
      }
    },

    handleEnd() {
      if (this.isDrawing) {
        this.isDrawing = false;
        this.pathsry.push(this.points);
      }
    },

    drawLine(context, x1, y1, x2, y2) {
      this.context.beginPath();
      this.context.strokeStyle = this.canvasOption.color;
      this.context.lineWidth = this.canvasOption.size;
      this.context.lineJoin = "miter";
      this.context.moveTo(x1, y1);
      this.context.lineTo(x2, y2);
      this.context.closePath();
      this.context.stroke();
    },
    createNew(node, type) {
      const actions = {
        folder: [
          {
            icon: "mdi-pencil",
            label: "Editar nombre",
            action: (node) => {
              node.isEditable = true;
            },
          },
          {
            icon: "mdi-folder-plus",
            label: "Crear carpeta",
            action: (node) => {
              this.createNew(node, "folder");
            },
          },
          {
            icon: "mdi-file-plus",
            label: "Crear archivo",
            action: (node) => {
              this.createNew(node, "file");
            },
          },
        ],
        file: [
          {
            icon: "mdi-pencil",
            label: "Editar nombre",
            action: (node) => {
              node.isEditable = true;
            },
          },
          {
            icon: "mdi-content-save",
            label: "Guardar dibujo",
            action: (node) => {
              node.canvas = this.canvas.toDataURL();
            },
          },
        ],
      };
      const emptyNode = Object.assign(
        { ...EMPTY_OBJECT },
        {
          type,
          iconColor: type === "folder" ? "amber" : "indigo",
          contractedIcon: type === "folder" ? "mdi-folder" : "mdi-file-outline",
          expandedIcon: type === "folder" ? "mdi-folder-open" : "",
          actions: actions[type],
          key: Math.random() * 1000,
          parent: node.key,
        }
      );
      Object.assign(node, {
        children: [...node.children, emptyNode],
      });
      node.isExpanded = true;
    },
  },
  mounted() {
    this.canvas = document.getElementById("drawZone");
    this.context = this.canvas.getContext("2d");
    document.addEventListener("DOMContentLoaded", this.startup);
  },
};
</script>

<style lang="scss" scoped>
.draw-section-height {
  height: calc(100% - 35px);
  overflow: hidden;
}
</style>
