<template>
  <div class="pa-2">
    <div class="d-flex justify-space-between align-center node">
      <div class="d-flex align-center ml-2">
        <v-btn
          icon
          small
          color="info"
          @click="
            {
              valueCopy.isExpanded = valueCopy.children.length
                ? !valueCopy.isExpanded
                : valueCopy.isExpanded;
            }
          "
        >
          <v-icon small>{{
            valueCopy.children.length
              ? valueCopy.isExpanded
                ? "mdi-chevron-up"
                : "mdi-chevron-down"
              : "mdi-vector-point"
          }}</v-icon>
        </v-btn>
        <v-icon :color="valueCopy.iconColor" small>{{
          valueCopy.contractedIcon
        }}</v-icon>
        <div class="ml-2">
          <p v-if="!valueCopy.isEditable" class="ma-0 text-body-2 py-2">
            {{ valueCopy.label }}
          </p>
          <v-text-field
            v-else
            v-model="valueCopy.label"
            dense
            hide-details
            class="py-1"
            color="blue darken-2"
            solo
          >
            <template #append>
              <v-btn
                icon
                small
                @click="
                  {
                    valueCopy.isEditable = false;
                  }
                "
              >
                <v-icon small>mdi-content-save</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          v-for="(action, index) in valueCopy.actions"
          :key="`${valueCopy.key}-${index}`"
          icon
          small
          @click="action.action(valueCopy)"
        >
          <v-icon small>{{ action.icon }}</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-show="valueCopy.isExpanded">
      <tree-node
        v-for="(nod, ind) in valueCopy.children"
        :key="`${valueCopy.key}-${ind}`"
        v-model="valueCopy.children[ind]"
        :selected.sync="selectedCopy"
        :level="1"
      />
    </div>
  </div>
</template>

<script>
import TreeNode from "./Node.vue";
export default {
  name: "TreeComponent",
  components: { TreeNode },
  props: {
    value: {
      type: Object,
      required: true,
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      valueCopy: null,
      selectedCopy: {},
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(current) {
        if (JSON.stringify(current) !== JSON.stringify(this.valueCopy)) {
          this.valueCopy = { ...current };
        }
      },
    },
    valueCopy: {
      deep: true,
      immediate: true,
      handler(current) {
        if (JSON.stringify(current) !== JSON.stringify(this.value)) {
          this.$emit("input", current);
        }
      },
    },
    selectedCopy: {
      deep: true,
      immediate: true,
      handler(current) {
        if (JSON.stringify(current) !== JSON.stringify(this.selected)) {
          this.$emit("update:selected", { ...current });
        }
      },
    },
    selected: {
      deep: true,
      immediate: true,
      handler(current) {
        if (JSON.stringify(current) !== JSON.stringify(this.selectedCopy)) {
          this.selectedCopy = { ...current };
        }
      },
    },
  },
};
</script>

<style></style>
