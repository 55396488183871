var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"d-flex justify-space-between align-center node"},[_c('div',{staticClass:"d-flex align-center ml-2"},[_c('v-btn',{attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){{
            _vm.valueCopy.isExpanded = _vm.valueCopy.children.length
              ? !_vm.valueCopy.isExpanded
              : _vm.valueCopy.isExpanded;
          }}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.valueCopy.children.length ? _vm.valueCopy.isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" : "mdi-vector-point"))])],1),_c('v-icon',{attrs:{"color":_vm.valueCopy.iconColor,"small":""}},[_vm._v(_vm._s(_vm.valueCopy.contractedIcon))]),_c('div',{staticClass:"ml-2"},[(!_vm.valueCopy.isEditable)?_c('p',{staticClass:"ma-0 text-body-2 py-2"},[_vm._v(" "+_vm._s(_vm.valueCopy.label)+" ")]):_c('v-text-field',{staticClass:"py-1",attrs:{"dense":"","hide-details":"","color":"blue darken-2","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){{
                  _vm.valueCopy.isEditable = false;
                }}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")])],1)]},proxy:true}]),model:{value:(_vm.valueCopy.label),callback:function ($$v) {_vm.$set(_vm.valueCopy, "label", $$v)},expression:"valueCopy.label"}})],1)],1),_c('div',{staticClass:"d-flex"},_vm._l((_vm.valueCopy.actions),function(action,index){return _c('v-btn',{key:`${_vm.valueCopy.key}-${index}`,attrs:{"icon":"","small":""},on:{"click":function($event){return action.action(_vm.valueCopy)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(action.icon))])],1)}),1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.valueCopy.isExpanded),expression:"valueCopy.isExpanded"}]},_vm._l((_vm.valueCopy.children),function(nod,ind){return _c('tree-node',{key:`${_vm.valueCopy.key}-${ind}`,attrs:{"selected":_vm.selectedCopy,"level":1},on:{"update:selected":function($event){_vm.selectedCopy=$event}},model:{value:(_vm.valueCopy.children[ind]),callback:function ($$v) {_vm.$set(_vm.valueCopy.children, ind, $$v)},expression:"valueCopy.children[ind]"}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }